
















import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";

@Component({
  components: {}
})
export default class SygniFileBox extends Vue {
  @Prop() icon: string;
  @Prop() actionTooltip: string;
  @Prop({ default: false }) showPreview: boolean;
  @Prop({ default: false }) readOnlyMode: boolean;
  @Prop({ default: false }) draggable: boolean;
}

