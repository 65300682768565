var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_1304_1268)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M22.5 21.675H2.3625V1.50001C2.3625 1.05001 1.9875 0.637512 1.5 0.637512C1.0125 0.637512 0.675003 1.05001 0.675003 1.50001V21.7875C0.675003 22.65 1.3875 23.3625 2.25 23.3625H22.5C22.95 23.3625 23.3625 22.9875 23.3625 22.5C23.3625 22.0125 22.95 21.675 22.5 21.675Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M22.125 8.28754H17.7375C17.2875 8.28754 16.875 8.66254 16.875 9.15004C16.875 9.63754 17.25 10.0125 17.7375 10.0125H20.2875L18.3 11.7375H13.3875C13.125 11.7375 12.8625 11.85 12.7125 12.075L10.05 15.5625H6.1125C5.6625 15.5625 5.25 15.9375 5.25 16.425C5.25 16.9125 5.625 17.2875 6.1125 17.2875H10.4625C10.725 17.2875 10.9875 17.175 11.1375 16.95L13.8 13.4625H18.6375C18.825 13.4625 19.05 13.3875 19.2 13.2375L21.675 11.0625V13.95C21.675 14.4 22.05 14.8125 22.5375 14.8125C23.025 14.8125 23.4 14.4375 23.4 13.95V9.48754C23.3625 8.85004 22.8 8.28754 22.125 8.28754Z",
      "fill": "currentColor"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_1304_1268"
    }
  }, [_c('rect', {
    attrs: {
      "width": "24",
      "height": "24",
      "fill": "none"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }