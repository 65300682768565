var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-container-title', _vm.inline ? 'sygni-container-title--inline' : '', !_vm.legacyStyling ? 'tw-theme' : '']
  }, [_c('div', {
    ref: 'title',
    staticClass: "title"
  }, [_vm._t("default")], 2), _c('div', {
    ref: 'titleSpace',
    staticClass: "title-remaining-space"
  }, [_vm._t("actions")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }