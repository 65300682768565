import {Statues} from "@/shared/interfaces/Statues";
import {Price} from "@/modules/genprox/models/Shared";
import {TableQuery} from "@/modules/shared/utils/TableQuery";
import {Query} from "@/shared/interfaces/Common";
import {InvestmentClientStatus} from "@/modules/genprox/modules/fund/modules/fundraising/models/InvestmentClientStatus.type";

export interface InvestorsState {
    investors: Array<Investor> | null;
    kycTable: Array<any> | null;
    kycTableLength: number,
    investorsLength: number,
    investorsQuery: Query,
    kycTableQuery: Query,
    productsQuery: string,
    productsQueryNEW: TableQuery,
    transactionsHistoryQuery: TableQuery,
    newInvestmentClient: InvestmentClient;
    editInvestmentClientData: InvestmentClient;
    activeProduct: NewProduct;
    kycTableBusy: boolean;
    kycTablePerPage: number;
    investorsTableBusy: boolean;
    investorsTablePerPage: number;
    annexingProducts: any[],
    taxOfficeCodes: any[],
    propagate: boolean,
    autentiStatusData: any,
    productCodeFormats: CodeFormat[]
}

export interface CodeFormat {
    label: string,
    value: string,
    prefixRequired: boolean,
}

export interface Investor {
    created: string,
    id: string,
    name: string,
    description?: string,
    photo: string,
    productStatuses: Array<Statues>,
    sourceName: string,
    sourceType?: string,
    status: string,
    totalInvestmentValue: number,
    details?: NewProduct,
    lastLogged?: string,
    notes?: string,
}

export interface LegacyProduct {
    investmentClientId: string,
    code: string,
    name: string,
    investmentValue: Price,
    agreementDate: string,
    agreementTemplateId?: string,
    customAttachmentIds?: string[],
    type: ProductTypes,
    data?: Loan,
    editable: boolean,
}

export interface NewProduct {
    id?: string,
    investmentClientId?: string,
    investorLegalEntityId?: string,
    subscriptionId?: string,
    code?: string,
    autogeneratedCode?: boolean,
    name: string,
    investmentValue?: Price,
    currency?: string,
    type: ProductTypes,
    agreementDate?: string,
    agreementTemplateId?: string,
    customAttachmentIds?: string[],
    loanData?: Loan,
    billAgreementData?: BillAgreement,
    editable?: boolean,
    paymentDate?: string,
    paymentValue?: number,
    rate?: number,
    rateDate?: string,
    capitalGainsTax?: boolean,
    clatTax?: boolean,
    data?: Loan | BillAgreement | LlcShares | JointStockShares,
    annexType?: 'annex' | 'rollover',
    annexSourceReferenceIds?: string[],
    annotation?: string,
    investmentValueStep?: number,
    investmentPaymentValue?: {
        min: number | null,
        max: number | null,
    },
}

export interface Product extends NewProduct{
    id: string,
    status: ProductStatus,
}

export interface GetProduct {
    type: 'for-legal-entity' | 'for-investment-client';
    id: string
}

export interface LegacyLoan {
    fixedRepaymentDate? : string,
    timeType : string,
    durationType : string,
    durationCount : number,
    interestType : string,
    advancedOptions: boolean,
    interest?: number,
    interestPayment?: string,
    interestPaidWithin : number,
    interestCalculationMethod : string,
    initialPaymentBankAccount: string,
    principalPaymentBankAccount : string,
    interestPaymentBankAccount : string,
    debtor: string,
    commission?: number,
    commissionType?: string, // TODO: This should be enum
    margin?: number,
    commissionPayment?: string,  // TODO: This should be enum
    marginPayment?: string // TODO: This should be enum
}

export interface BankAccount {
    name: string | null,
    owner: string | null,
    account: string,
    country: string,
    swift: string | null,
}

export interface DebtorAccount {
    account: string,
    name: string,
    currency: string | null,
    type: string | null, 
}

export interface BillAgreement {
    currency: 'PLN',
    timeType: string,
    fixedRepaymentDate?: string | null,
    duration: { 
        type: string, 
        count: number 
    } | null,
    interestType: string,
    enabledAdvancedOptions: boolean,
    advancedOptions?: {
        commission: number,
        commissionType: string,
        commissionPayment: string,
        margin: number,
        marginPayment: string,
    } | null,
    simpleOptions?: {
        interest: number,
        interestPayment: string,
    } | null,
    interestPaidWithin : number,
    interestCalculationMethod : string,
    initialPaymentBankAccount?: BankAccount,
    principalPaymentBankAccount?: BankAccount,
    interestPaymentBankAccount?: BankAccount,
    debtor: DebtorAccount,
    billNumber?: string | null,
    iborType?: string,
}

export interface LlcShares {
    llcShares: {
        instrumentCount: number,
        nominalValue: number,
        issueValue: number,
    },
    [key:string]: any
}

export interface JointStockShares {
    seriesOfShares: {
        name: string,
        instrumentCount: number,
        counterFrom: number,
        counterTo: number,
        nominalValue: number,
        issueValue: number,
    },
    [key:string]: any
}

export interface Loan {
    currency: 'PLN',
    timeType: string,
    fixedRepaymentDate?: string | null,
    duration: { 
        type: string, 
        count: number 
    } | null,
    interestType: string,
    enabledAdvancedOptions: boolean,
    advancedOptions?: {
        commission: number,
        commissionType: string,
        commissionPayment: string,
        margin: number,
        marginPayment: string,
    } | null,
    simpleOptions?: {
        interest: number,
        interestPayment: string,
    } | null,
    interestPaidWithin : number,
    interestCalculationMethod : string,
    initialPaymentBankAccount?: BankAccount,
    principalPaymentBankAccount?: BankAccount,
    interestPaymentBankAccount?: BankAccount,
    debtor: DebtorAccount,
    billNumber?: string,
    iborType?: string,
}

export enum ProductTypes {
    loan = 'LOAN',
    billAgreement = 'BILL_AGREEMENT',
    llcShares = 'LLC_SHARES',
    jointStockShares = 'JOINT_STOCK_SHARES'
}

export enum TimeTypes {
    fixedRepaymentDate = 'fixed_repayment_date',
    duration = 'duration',
}

export interface ProductStatus {

}

export interface InvestmentClient {
    firstName: string,
    surname: string,
    email: string,
    investmentPotential?: string,
    investmentKnowledge?: string,
    industry?: Array<string>,
    notes?: string,
    isMailingAddressSameResidential?: boolean
    details?: InvestmentClientDetails,
    residentialAddress?: Address,
    mailingAddress?: Address | false,
    status?: InvestmentClientStatus;
    consents?: any
}

export interface InvestmentClientDetails {
    title?: string,
    middleName?: string,
    birthDate?: string,  //date ?
    birthPlace?: string,
    fathersName?: string,
    mothersName?: string,
    phone?: string, // number raczej nie bo plus +48
    identificationNumber?: string,
    documentType?: string,
    documentNumber?: string,
    documentExpiryDate?: {
        expiryDate: string,
        indefinitely: boolean,
    },
    // documentExpiryDate?: string // date?
    documentCountry?: string,
    country?: string,
    taxOffice?: string,
    nationality?: string,
}

export interface Address {
    street?: string,
    houseNumber?: string,
    flatNumber?: string,
    city?: string,
    zipCode?: string,
    country?: string,
    voivodeship?: string,
    county?: string,
    commune?: string,
}

export const emptyInvestmentClient: InvestmentClient = {
    firstName: '',
    surname: '',
    email: '',
    investmentPotential: null,
    investmentKnowledge: null,
    notes: null,
    isMailingAddressSameResidential : false,
    details: {
        title: null,
        middleName: null,
        birthDate: null,  //date ?
        birthPlace: null,
        fathersName: null,
        mothersName: null,
        phone: null, // number raczej nie bo plus +48
        identificationNumber: null,
        documentType: null,
        documentNumber: null,
        documentExpiryDate: {
            expiryDate: null,
            indefinitely: false,
        },
        documentCountry: null,
        country: null,
        nationality: null,
    },
    mailingAddress: {
        street: null,
        houseNumber: null,
        flatNumber: null,
        city: null,
        zipCode: null,
        country: null,
        voivodeship: null,
        county: null,
        commune: null
    },
    residentialAddress: {
        street: null,
        houseNumber: null,
        flatNumber: null,
        city: null,
        zipCode: null,
        country: null,
        voivodeship: null,
        county: null,
        commune: null
    },
    consents: [],
}

export const emptyLlcProduct: any = {
    investmentClientId: null,
    investorLegalEntityId: null,
    code: null,
    name: null,
    investmentValue: null,
    type: "LLC_SHARES",
    subscriptionId: null,
    agreementTemplateId: null,
    autogeneratedCode: true,
    customAttachmentIds: [],
    agreementDate: null,
    currency: "PLN",
    data: {
        llcShares: {
            instrumentCount: null,
            nominalValue: null,
            issueValue: null
        }
    },
    clatTax: true,
    capitalGainsTax: false
}

export const emptyJointStockSharesProduct: any = {
    investmentClientId: null,
    investorLegalEntityId: null,
    autogeneratedCode: true,
    code: null,
    name: null,
    investmentValue: null,
    type: "JOINT_STOCK_SHARES",
    subscriptionId: null,
    agreementTemplateId: null,
    customAttachmentIds: [],
    agreementDate: null,
    currency: "PLN",
    data: {
        seriesOfShares: {
            name: null,
            instrumentCount: null,
            counterFrom: null,
            counterTo: null,
            nominalValue: null,
            issueValue: null
        }
    },
    clatTax: true,
    capitalGainsTax: false
}

export const emptyLoanProduct: NewProduct = {
    investmentClientId: '',
    investorLegalEntityId: null,
    code: '',
    autogeneratedCode: true,
    name: '',
    investmentValue: null,
    currency: 'PLN',
    type: ProductTypes.loan,
    agreementTemplateId: '',
    customAttachmentIds: [],
    agreementDate: '',
    capitalGainsTax: true,
    clatTax: true,
    data: {
        currency: 'PLN',
        timeType: 'fixed_repayment_date',
        fixedRepaymentDate: '',
        duration: null,
        interestType: 'Fixed',
        enabledAdvancedOptions: false,
        simpleOptions: {
            interest: null,
            interestPayment: 'Monthly',
        },
        advancedOptions: {
            commission: null,
            commissionPayment: '',
            commissionType: '',
            margin: null,
            marginPayment: '',
        },
        interestPaidWithin: 0,
        interestCalculationMethod: '30/365',
        initialPaymentBankAccount: {
            name: null,
            owner: null,
            account: '',
            swift: null,
            country: 'PL',
        },
        principalPaymentBankAccount: {
            name: null,
            owner: null,
            account: '',
            swift: null,
            country: 'PL',
        },
        interestPaymentBankAccount: {
            name: null,
            owner: null,
            account: '',
            swift: null,
            country: 'PL',
        },
        debtor: {
            account: '',
            currency: null,
            name: '',
            type: null,
        },
    },
    editable: false,
}

export const emptyBillProduct: NewProduct = {
    investmentClientId: '',
    investorLegalEntityId: null,
    code: '',
    autogeneratedCode: true,
    name: '',
    investmentValue: null,
    currency: 'PLN',
    type: ProductTypes.billAgreement,
    agreementTemplateId: '',
    customAttachmentIds: [],
    agreementDate: '',
    capitalGainsTax: true,
    clatTax: true,
    data: {
        currency: 'PLN',
        timeType: 'fixed_repayment_date',
        fixedRepaymentDate: '',
        duration: null,
        interestType: 'Fixed',
        enabledAdvancedOptions: false,
        simpleOptions: {
            interest: null,
            interestPayment: 'Monthly',
        },
        advancedOptions: {
            commission: null,
            commissionPayment: '',
            commissionType: '',
            margin: null,
            marginPayment: '',
        },
        interestPaidWithin: 0,
        interestCalculationMethod: '30/365',
        initialPaymentBankAccount: {
            name: '',
            owner: null,
            account: '',
            swift: null,
            country: 'PL',
        },
        principalPaymentBankAccount: {
            name: '',
            owner: null,
            account: '',
            swift: null,
            country: 'PL',
        },
        interestPaymentBankAccount: {
            name: '',
            owner: null,
            account: '',
            swift: null,
            country: 'PL',
        },
        debtor: {
            account: '',
            currency: null,
            name: '',
            type: null,
        },
        billNumber: '',
    },
    editable: false,
}

export enum ProductSummaryAction {
    add = 'add',
    acceptByIdByInvestmentClient = 'acceptByIdByInvestmentClient',
    summaryByLegalEntity = 'summaryByLegalEntity',
    summaryByInvestmentClient = 'summaryByIntestmentClient',
    acceptByIdByLegalEntity = 'acceptByIdByLegalEntity'
}

export interface EditInvestmentClientPayload {
    id: string,
    data: InvestmentClient
}
