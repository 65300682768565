var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['file-uploader', _vm.type == 'simple' ? 'file-uploader--simple' : '', _vm.inverted ? 'file-uploader--inverted' : '']
  }, [_c('sygni-droparea', {
    attrs: {
      "disabled": _vm.isDisabled,
      "dropAreaText": _vm.dropAreaText
    },
    on: {
      "change": function change($event) {
        return _vm.addFiles($event);
      }
    }
  }), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.tooltipText,
      expression: "tooltipText",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "upload-file-container"
  }, [!_vm.isUploading ? _c('GenproxButton', {
    attrs: {
      "outline": true,
      "icon": _vm.CloudArrowDownIcon,
      "isDisabled": _vm.isDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.openFileSelector();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.btnText) + " ")]) : _vm._e(), _vm.isUploading ? _c('b-progress', {
    staticClass: "mt-2 w-100",
    attrs: {
      "value": _vm.uploadProgress.progress,
      "variant": "danger",
      "striped": true
    }
  }) : _vm._e(), _vm.infoText ? _c('span', {
    staticClass: "upload-text"
  }, [_vm._v(_vm._s(_vm.infoText))]) : _vm._e(), _vm._t("additionalContent"), _c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "fileInput",
    attrs: {
      "type": "file",
      "multiple": _vm.multiple
    },
    on: {
      "change": function change($event) {
        return _vm.handleFileInput($event);
      }
    }
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }