


















import LoaderIcon from '@/assets/icons/heroicons/LoaderIcon.vue';
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from "vue-property-decorator";

@Component({
  components: { LoaderIcon }
})
export default class GenproxButton extends Vue {
  @Prop({ default: '' }) variant: '' | 'success' | 'danger' | 'gray';
  @Prop({ default: false }) isDisabled!: boolean;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: false }) isSquare: boolean;
  @Prop({ default: false }) outline: boolean;
  @Prop({ default: null }) counter: number;
  @Prop({ default: null }) icon: any;
  @Prop({ default: 'left' }) iconPosition: 'left' | 'right';

  @Prop({default: false}) selectable!: boolean;
  @Prop({default: true}) hoverable!: boolean;
  @Prop({default: ''}) tooltip: string;

  @Emit() click() {
    if(!this.isDisabled && !this.isLoading){
      return true;
    }
  }

  toggleActive(): void {
    this.click();
  }
}
