var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M11.25 11.5625H10.5V7.40625H10.7187C11.0625 7.40625 11.375 7.15625 11.4062 6.8125L11.75 4.25C11.7812 4.0625 11.7187 3.84375 11.5937 3.6875C11.4687 3.53125 11.2813 3.4375 11.0625 3.4375H8.5C8.28125 3.4375 8.09375 3.53125 7.96875 3.6875C7.84375 3.84375 7.78125 4.03125 7.8125 4.25L8.15625 6.8125C8.21875 7.15625 8.5 7.40625 8.84375 7.40625H9.0625V11.5625H8.3125C7.9375 11.5625 7.625 11.875 7.625 12.25V14.4375C7.625 15.625 8.59375 16.5938 9.78125 16.5938C10.9687 16.5938 11.9375 15.625 11.9375 14.4375V12.25C11.9375 11.9062 11.625 11.5625 11.25 11.5625ZM10.25 4.8125L10.0937 5.96875H9.46875L9.3125 4.8125H10.25ZM10.5312 14.4688C10.5312 14.875 10.1875 15.2188 9.78125 15.2188C9.375 15.2188 9.03125 14.875 9.03125 14.4688V13H9.0625C9.0625 13.375 9.375 13.6875 9.75 13.6875C10.125 13.6875 10.4375 13.375 10.4375 13H10.5V14.4688H10.5312Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M5.8125 3.8125C5.625 3.5625 5.3125 3.375 4.96875 3.375H2.3125C1.34375 3.375 0.53125 4.15625 0.53125 5.15625V5.875C0.53125 6.84375 1.3125 7.65625 2.3125 7.65625H2.65625V15.5625C2.65625 15.9375 2.96875 16.25 3.34375 16.25C3.71875 16.25 4.03125 15.9375 4.03125 15.5625V7.625H4.9375C5.28125 7.625 5.59375 7.46875 5.78125 7.1875L6.59375 6.125C6.875 5.75 6.875 5.21875 6.59375 4.84375L5.8125 3.8125ZM4.8125 6.21875H2.3125C2.125 6.21875 1.9375 6.0625 1.9375 5.84375V5.125C1.9375 4.9375 2.09375 4.75 2.3125 4.75H4.8125L5.34375 5.46875L4.8125 6.21875Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M18.4375 11.4375V8.3125L19.2812 6.875C19.5312 6.4375 19.5312 5.90625 19.2812 5.46875L18.3437 3.875C18.1562 3.53125 17.7187 3.4375 17.375 3.625C17.0312 3.8125 16.9375 4.25 17.125 4.59375L18.0625 6.1875L17.125 7.78125C17.0625 7.875 17.0312 8 17.0312 8.125V11.625C17.0312 11.75 17.0625 11.875 17.125 11.9687L18.0625 13.5625L17.3437 14.8125H15.9062L15.1875 13.5625L16.125 11.9687C16.1875 11.875 16.2187 11.75 16.2187 11.625V8.09375C16.2187 7.96875 16.1875 7.84375 16.125 7.75L15.1875 6.15625L16.125 4.5625C16.3125 4.21875 16.2187 3.78125 15.875 3.59375C15.5312 3.40625 15.0937 3.5 14.9062 3.84375L13.9687 5.4375C13.7188 5.875 13.7188 6.40625 13.9687 6.84375L14.8125 8.28125V11.4062L13.9687 12.8437C13.7188 13.2812 13.7188 13.8125 13.9687 14.25L14.6875 15.5C14.9375 15.9375 15.4062 16.2188 15.9062 16.2188H17.3437C17.8437 16.2188 18.3125 15.9375 18.5625 15.5L19.2812 14.25C19.5312 13.8125 19.5312 13.2812 19.2812 12.8437L18.4375 11.4375Z",
      "fill": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }