import {RouteConfig} from "vue-router";
import store from "@/store/rootStore";

const portfolioModule = () => import('./containers/portfolioModule.vue');
const portfolioBlindModule = () => import('./containers/portfolioBlindModule.vue');

export const portfolioRouting: Array<RouteConfig>  = [
    {
        path: 'portfolio/by-company',  /* /fund */
        name: 'portfolio-by-company',
        // beforeEnter: (to, from, next) => {
        //     window.location.href = `${BACKEND_BASE_URL}/fund/portfolio/by-company`
        // },
        meta: {
            breadcrumbs: [
                { text: 'Home', href: '/welcome' },
                { text: ':currentContext', href: '/:currentContext/dashboard' },
                { text: 'Portfolio', href: '#' },
                { text: 'Investment by company', href: '' },
            ],
        },
        component: portfolioModule,
        async beforeEnter(to, from, next) {
            let activeUserData = store.getters['genprox/activeUserData'];
            if(!activeUserData) {
                activeUserData = await store.dispatch('genprox/getActiveUserData');
            }

            if(activeUserData?.context?.context == 'fund') {
                if (to?.path?.includes('/company')) {
                    const viewedId = to?.path?.includes('/company') ? await store.dispatch('auth/getViewedCompany') : await store.dispatch('auth/getViewedFund')
                    const leAccessModules = await store.dispatch('genprox/getLegalEntityAccessModules', viewedId);

                    if(leAccessModules.portfolio) {
                        next();
                    } else {
                        next(`${to.path}/access-denied`);
                    }
                } else {
                    await store.dispatch('genprox/getAccessModules');
                    const accessModules = store.getters['genprox/accessModules'];
                    if(accessModules.portfolio) {
                        next();
                    } else {
                        next(`${to.path}/access-denied`);
                    }
                }
            } else if (activeUserData?.context?.context == 'company') {
                const leAccessModules = await store.dispatch('genprox/getLegalEntityAccessModules');

                if(leAccessModules.portfolio) {
                    next();
                } else {
                    next(`${to.path}/access-denied`);
                }

            } else {
                const viewedId = to?.path?.includes('/company') ? await store.dispatch('auth/getViewedCompany') : await store.dispatch('auth/getViewedFund')
                const leAccessModules = await store.dispatch('genprox/getLegalEntityAccessModules', viewedId);

                if(leAccessModules.portfolio) {
                    next();
                } else {
                    next(`${to.path}/access-denied`);
                }
            }
        },
    },
    {
        path: 'portfolio/by-company/access-denied',
        name: 'access-denied',
        component: portfolioBlindModule,
        meta: {
            accessModule: ['portfolio'],
        },
        children: [],
    },
];
