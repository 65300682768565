











import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";

@Component
export default class SygniContainerTitle extends Vue {
  @Prop({default: true}) legacyStyling: boolean;
  @Prop({default: 1}) lineHeight: number;
  @Prop({default: false}) inline: boolean;

  mounted(){
    (this.$refs.titleSpace as HTMLDivElement).style.borderWidth = `${this.lineHeight}px`;
    (this.$refs.title as HTMLDivElement).style.borderWidth = `${this.lineHeight}px`;
  }
}
