var render = function () {
  var _vm$$slots;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['gn-button', !_vm.hoverable ? 'not-hoverable' : '', _vm.isSquare ? 'gn-button--square' : '', _vm.outline ? 'gn-button--outlined' : '', _vm.isDisabled ? 'gn-button--disabled' : '', _vm.variant, _vm.iconPosition == 'right' ? 'gn-button--inverse' : '']
  }, [_c('button', {
    staticClass: "gn-button__btn",
    attrs: {
      "disabled": _vm.isDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.toggleActive();
      }
    }
  }, [_vm.icon || _vm.isLoading ? _c('div', {
    staticClass: "gn-button__icon"
  }, [_vm.isLoading ? _c('LoaderIcon') : _c(_vm.icon, {
    tag: "component"
  })], 1) : _vm._e(), (_vm$$slots = _vm.$slots) !== null && _vm$$slots !== void 0 && _vm$$slots.default ? _c('div', {
    staticClass: "gn-button__text"
  }, [_vm.isLoading ? [_vm._v("Loading...")] : [_vm._t("default")]], 2) : _vm._e(), (_vm.counter || _vm.counter === 0) && !_vm.isLoading ? _c('div', {
    staticClass: "gn-button__counter"
  }, [_vm._v(_vm._s(_vm.counter))]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }