
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/types";
import { _axios as axios } from "@/plugins/axios";
import {BACKEND_API_URL} from "@/shared/consts";
import { AutentiConfiguration, AutentiParticipant, Template, TemplateAttachment, TemplatesState } from "./types";
import { Sorting, TableQuery } from "@/modules/shared/utils/TableQuery";

export const state: TemplatesState = {
  templatesTable: {
    items: [],
    totalCount: 0,
    perPage: 10,
    query: new TableQuery(10),
  },
  templatesTableIsBusy: false,
  autentiDictionaries: null,
}

export const getters: GetterTree<TemplatesState, RootState> = {
  getTemplatesTableItems(state) {
    return JSON.parse(JSON.stringify(state.templatesTable.items));
  },
  getTemplatesTableQuery(state) {
    return JSON.parse(JSON.stringify(state.templatesTable.query));
  },
  getTemplatesTablePerPage(state) {
    return JSON.parse(JSON.stringify(state.templatesTable.perPage));
  },
  getAutentiDictionaries(state) {
    return JSON.parse(JSON.stringify(state.autentiDictionaries));
  }
}

export const mutations: MutationTree<TemplatesState> = {
  setTemplatesTableItems(state, payload: {items: Array<any>, totalCount: number}): void {
    state.templatesTable = { ...state.templatesTable, items: payload.items, totalCount: payload.totalCount};
  },
  setTemplatesTableQuery(state, payload: TableQuery): void {
    state.templatesTable.query = payload;
  },
  setTemplatesTableSortingQuery(state, payload: Sorting): void {
    state.templatesTable.query.sorting = payload;
    state.templatesTable.query.offset = 0;
  },
  setTemplatesTableBusy(state,payload: boolean): void {
    state.templatesTableIsBusy = payload;
  },
  setTemplatesTablePerPage(state, payload: number): void {
    state.templatesTable.perPage = payload;
  },
  setTemplatesTableFiltersQuery(state, payload: string) {
    state.templatesTable.query.filters = payload;
  },
  setAutentiDictionaries(state, payload: any) {
    state.autentiDictionaries = payload;
  },
}

export const actions: ActionTree<TemplatesState, RootState> = {
  async getTemplates({state,commit}, filtersQuery?: string): Promise<Array<any>> {
    const queryString = state.templatesTable.query.getStringQuery();
    filtersQuery = filtersQuery ? filtersQuery : '';
    const queryData = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/agreement/templates${queryString}${filtersQuery}`, { headers: { 'x-total-count': true} });

    const payload: any = { items: queryData.data, totalCount: queryData.headers['x-total-count'] }

    commit('setTemplatesTableItems', {
      items: payload.items,
      totalCount: payload.totalCount,
    });

    return payload.items;
  },
  async getActiveAttachments({state,commit}): Promise<any[]> {
    const response = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/agreement/templates?limit=9999999&filters[isAttachment][0][value]=1&filters[isAttachment][0][operator]=eq&filters[status][0][value]=ACTIVE&filters[status][0][operator]=eq`);

    return response.data;
  },
  async getActiveTemplates({state,commit}): Promise<any[]> {
    const response = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/agreement/templates?limit=9999999&filters[isAttachment][0][value]=0&filters[isAttachment][0][operator]=eq&filters[status][0][value]=ACTIVE&filters[status][0][operator]=eq`);

    return response.data;
  },
  async getTemplateChoices({state,commit}, payload: { type: 'template' | 'attachment' | 'all', context: string }): Promise<any[]> {
    const response = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/agreement/template-choices/${payload?.context}`)

    let data = Object.values(response?.data)

    if (payload?.type !== 'all') {
      data = data?.filter((el: any) => {
        return (payload?.type === 'template') ? !el.isAttachment : el.isAttachment
      })
    }

    data = data?.filter((el: any) => el?.status?.toLowerCase() === 'active')

    return data
  },
  async getTemplate({state}, templateId: string) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/agreement/template/${templateId}`);

    return data;
  },
  async getAttachment({state}, attachmentId: string) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/agreement/attachment/${attachmentId}`);

    return data;
  },
  async getAgreementTemplate({state}, agreementId: string) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/investor/agreement/template/${agreementId}`);

    return data;
  },
  async postTemplate({state}, payload: Template) {
    // delete id property from object
    delete payload.id;

    const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/agreement/template`, payload);

    return data;
  },
  async postAttachments({state}, payload: { attachmentId: string, attachments: TemplateAttachment[] }) {
    const promises: any[] = [];

    payload.attachments.forEach((attachment: TemplateAttachment) => {
      promises.push(axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/agreement/template/${payload.attachmentId}/attachment`, attachment));
    })

    const response = await Promise.all(promises);

    return response;
  },
  async editAttachments({state}, payload: { attachmentId: string, attachments: TemplateAttachment[] }) {
    const promises: any[] = [];

    payload.attachments.forEach((attachment: TemplateAttachment) => {
      const id = attachment.id;

      // delete id property from object
      delete attachment.id;

      promises.push(axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/agreement/template/${payload.attachmentId}/attachment/${id}`, attachment));
    })

    const response = await Promise.all(promises);

    return response;
  },
  async deleteAttachments({state}, payload: { attachmentId: string, attachmentIds: string[] }) {
    const promises: any[] = [];

    payload.attachmentIds.forEach((id: string) => {
      promises.push(axios.delete(`${BACKEND_API_URL}/marketplace/legal-entity/agreement/template/${payload.attachmentId}/attachment/${id}`));
    })

    const response = await Promise.all(promises);

    return response;
  },
  async editTemplate({state}, payload: Template) {
    const id = payload.id;

    // delete id property from object
    delete payload.id;

    const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/agreement/template/${id}`, payload);

    return data;
  },
  async activateTemplate({state}, templateId: string) {
    const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/agreement/template/${templateId}/activate`);

    return data;
  },
  async archiveTemplate({state}, templateId: string) {
    const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/agreement/template/${templateId}/archive`);

    return data;
  },
  async draftTemplate({state}, templateId: string) {
    const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/agreement/template/${templateId}/set-as-draft`);

    return data;
  },
  async getAutentiDictionaries({state, commit}) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/autenti/dictionaries`);

    commit('setAutentiDictionaries', data)

    return data;
  },
  async getAutentiGlobalConfig({state}) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/autenti/global-configuration`);

    return data;
  },
  async getAutentiDocumentConfig({state}, templateId: string) {
    const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/autenti/document-configuration/${templateId}`);

    return data;
  },
  async saveAutentiGlobalConfig({state}, payload: AutentiConfiguration) {
    const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/autenti/global-configuration`, payload);

    return data;
  },
  async saveAutentiDocumentConfig({state}, payload: { templateId: string, data: AutentiConfiguration }) {
    const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/autenti/document-configuration/${payload?.templateId}`, payload?.data);

    return data;
  },
  async addGlobalParticipant({state}, payload: AutentiParticipant) {
    const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/autenti/global-configuration/participant`, payload);

    return data;
  },
  async addDocumentParticipant({state}, payload: { documentId: string, data: AutentiParticipant }) {
    const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/autenti/document-configuration/${payload?.documentId}/participant`, payload?.data);

    return data;
  },
  async editGlobalParticipant({state}, payload: { participantId: string, data: AutentiParticipant }) {
    const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/autenti/global-configuration/participant/${payload?.participantId}`, payload?.data);

    return data;
  },
  async editDocumentParticipant({state}, payload: { participantId: string, documentId: string, data: AutentiParticipant }) {
    const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/autenti/document-configuration/${payload?.documentId}/participant/${payload?.participantId}`, payload?.data);

    return data;
  },
  async deleteGlobalParticipant({state}, id: string) {
    const { data } = await axios.delete(`${BACKEND_API_URL}/marketplace/legal-entity/autenti/global-configuration/participant/${id}`)

    return data
  },
  async deleteDocumentParticipant({state}, payload: { documentId: string, participantId: string }) {
    const { data } = await axios.delete(`${BACKEND_API_URL}/marketplace/legal-entity/autenti/document-configuration/${payload?.documentId}/participant/${payload?.participantId}`)

    return data
  },
}

export const templates: Module<TemplatesState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

