import {NavigationGuard, NavigationGuardNext, Route, RouteConfig} from "vue-router";
import store from "@/store/rootStore";
import rootStore from "@/store/rootStore";
import { Campaign } from "./store/types";
import moment from "moment";
import Vue from 'vue';
import { Contexts } from "@/modules/genprox/models/User";

const addNewCampaignModule = () => import('./containers/AddNewCampaignModule.vue');
const newCampaignFormStepOneComponent = () => import('../../components/add-new-campaign/AddNewCampaignFormStepOne.vue');
const newCampaignFormStepTwoComponent = () => import('../../components/add-new-campaign/AddNewCampaignFormStepTwo.vue');
const newCampaignFormStepThreeComponent = () => import('../../components/add-new-campaign/AddNewCampaignFormStepThree.vue');
const newCampaignFormStepFourComponent = () => import('../../components/add-new-campaign/AddNewCampaignFormStepFour.vue');
const newCampaignPreviewComponentModule = () => import('./containers/CampaignPreviewModule.vue');

const leadFormModule = () => import('./containers/leadFormModule.vue');

export const campaignsRouting: Array<RouteConfig>  = [
    {
        path: 'fundraising/lead-form-settings',
        name: 'leadFormSettings',
        component: leadFormModule,
        async beforeEnter(to, from, next) {
            await store.dispatch('campaigns/getLeadFormParams');
            next();
        }
    },
    {
        path: 'capital-rise/lead-form-settings',
        redirect: 'fundraising/lead-form-settings'
    },
    {
        path: 'fundraising/new-campaign',
        name: 'newCampaign',
        alias: ['investors/new-campaign'],
        meta: {
            breadcrumbs: [
                { text: 'Home', href: '/welcome' },
                { text: ':currentContext', href: '/:currentContext/dashboard' },
                { text: 'Fundraising', href: '/:currentContext/fundraising' },
                { text: 'Campaigns', href: '/:currentContext/fundraising?page=campaigns' },
                { text: 'New campaign', href: '' },
            ]
        },
        component: addNewCampaignModule,
        // beforeEnter: outsideEnterGuard('investors'),
        redirect: { name: 'newCampaignFormStepOne' },
        children: [
            {
                path: 'form/step-one',
                name: 'newCampaignFormStepOne',
                // props: () => ({ viewType: InvestorFormViewType.newInvestor }),
                component: newCampaignFormStepOneComponent,
                meta: {
                    breadcrumbs: [
                        { text: 'Home', href: '/welcome' },
                        { text: ':currentContext', href: '/:currentContext/dashboard' },
                        { text: 'Fundraising', href: '/:currentContext/fundraising' },
                        { text: 'Campaigns', href: '/:currentContext/fundraising?page=campaigns' },
                        { text: 'New campaign', href: '' },
                    ]
                },
            },
            {
                path: 'form/step-two',
                name: 'newCampaignFormStepTwo',
                // props: () => ({ viewType: InvestorFormViewType.newInvestor }),
                component: newCampaignFormStepTwoComponent,
                meta: {
                    breadcrumbs: [
                        { text: 'Home', href: '/welcome' },
                        { text: ':currentContext', href: '/:currentContext/dashboard' },
                        { text: 'Fundraising', href: '/:currentContext/fundraising' },
                        { text: 'Campaigns', href: '/:currentContext/fundraising?page=campaigns' },
                        { text: 'New campaign', href: '' },
                    ]
                },
            },
            {
                path: 'form/step-three',
                name: 'newCampaignFormStepThree',
                // props: () => ({ viewType: InvestorFormViewType.newInvestor }),
                component: newCampaignFormStepThreeComponent,
                meta: {
                    breadcrumbs: [
                        { text: 'Home', href: '/welcome' },
                        { text: ':currentContext', href: '/:currentContext/dashboard' },
                        { text: 'Fundraising', href: '/:currentContext/fundraising' },
                        { text: 'Campaigns', href: '/:currentContext/fundraising?page=campaigns' },
                        { text: 'New campaign', href: '' },
                    ]
                },
            },
            {
                path: 'form/step-four',
                name: 'newCampaignFormStepFour',
                // props: () => ({ viewType: InvestorFormViewType.newInvestor }),
                component: newCampaignFormStepFourComponent,
                meta: {
                    breadcrumbs: [
                        { text: 'Home', href: '/welcome' },
                        { text: ':currentContext', href: '/:currentContext/dashboard' },
                        { text: 'Fundraising', href: '/:currentContext/fundraising' },
                        { text: 'Campaigns', href: '/:currentContext/fundraising?page=campaigns' },
                        { text: 'New campaign', href: '' },
                    ]
                },
            },
        ]
    },
    {
        path: 'capital-rise/new-campaign',
        redirect: 'fundraising/new-campaign'
    },
    {
        path: 'fundraising/campaign/:id',
        name: 'editCampaign',
        alias: ['investors/campaign/:id'],
        meta: {
            breadcrumbs: [
                { text: 'Home', href: '/welcome' },
                { text: ':currentContext', href: '/:currentContext/dashboard' },
                { text: 'Fundraising', href: '/:currentContext/fundraising' },
                { text: 'Campaigns', href: '/:currentContext/fundraising?page=campaigns' },
                { text: 'Edit campaign', href: '' },
            ]
        },
        component: addNewCampaignModule,
        async beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
            try {
                const response = await rootStore.dispatch('campaigns/getCampaign', to.params.id);
                const campaignData: Campaign = {
                    attachments: response.attachments,
                    campaignName: response.campaignName,
                    id: response?.id,
                    data: response.data,
                    startDate: response.startDate ? moment(response.startDate).utc().format('YYYY-MM-DD') : null,
                    endDate: response.endDate ? moment(response.endDate).startOf('day').utc().format('YYYY-MM-DD') : null,
                    imageId: response.imageId,
                    imagePath: response.imagePath,
                    investmentValue: response.investmentValue,
                    investmentReach: response.investmentReach,
                    landingPageUrl: response.landingPageUrl,
                    type: response.type,
                    products: response?.products,
                    status: response.status,
                    issuerId: response?.issuerId || null,
                    issuerName: response?.issuerName || null,
                    productCodeFormat: response?.productCodeFormat || null,
                    productCodePrefix: response?.productCodePrefix || null,
                }

                if (!campaignData?.id) {
                    delete campaignData?.id
                }

                rootStore.commit('campaigns/setCampaignData', campaignData);
                next();
            } catch(e) {
                const errorMessage = 'Something went wrong. Try again later.';
                Vue.prototype.$notify({
                    duration: 2500,
                    type: 'error',
                    title: 'Error',
                    text: errorMessage
                });
                next(from);
            }
        },
        redirect: { name: 'editCampaignFormStepOne' },
        children: [
            {
                path: 'form/step-one',
                name: 'editCampaignFormStepOne',
                // props: () => ({ viewType: InvestorFormViewType.newInvestor }),
                component: newCampaignFormStepOneComponent,
                meta: {
                    breadcrumbs: [
                        { text: 'Home', href: '/welcome' },
                        { text: ':currentContext', href: '/:currentContext/dashboard' },
                        { text: 'Fundraising', href: '/:currentContext/fundraising' },
                        { text: 'Campaigns', href: '/:currentContext/fundraising?page=campaigns' },
                        { text: 'Edit campaign', href: '' },
                    ]
                },
            },
            {
                path: 'form/step-two',
                name: 'editCampaignFormStepTwo',
                // props: () => ({ viewType: InvestorFormViewType.newInvestor }),
                component: newCampaignFormStepTwoComponent,
                meta: {
                    breadcrumbs: [
                        { text: 'Home', href: '/welcome' },
                        { text: ':currentContext', href: '/:currentContext/dashboard' },
                        { text: 'Fundraising', href: '/:currentContext/fundraising' },
                        { text: 'Campaigns', href: '/:currentContext/fundraising?page=campaigns' },
                        { text: 'Edit campaign', href: '' },
                    ]
                },
            },
            {
                path: 'form/step-three',
                name: 'editCampaignFormStepThree',
                // props: () => ({ viewType: InvestorFormViewType.newInvestor }),
                component: newCampaignFormStepThreeComponent,
                meta: {
                    breadcrumbs: [
                        { text: 'Home', href: '/welcome' },
                        { text: ':currentContext', href: '/:currentContext/dashboard' },
                        { text: 'Fundraising', href: '/:currentContext/fundraising' },
                        { text: 'Campaigns', href: '/:currentContext/fundraising?page=campaigns' },
                        { text: 'Edit campaign', href: '' },
                    ]
                },
            },
            {
                path: 'form/step-four',
                name: 'editCampaignFormStepFour',
                // props: () => ({ viewType: InvestorFormViewType.newInvestor }),
                component: newCampaignFormStepFourComponent,
                meta: {
                    breadcrumbs: [
                        { text: 'Home', href: '/welcome' },
                        { text: ':currentContext', href: '/:currentContext/dashboard' },
                        { text: 'Fundraising', href: '/:currentContext/fundraising' },
                        { text: 'Campaigns', href: '/:currentContext/fundraising?page=campaigns' },
                        { text: 'Edit campaign', href: '' },
                    ]
                },
            },
        ]
    },
    {
        path: 'capital-rise/campaign/:id',
        redirect: 'fundraising/campaign/:id'
    },
    {
        path: 'capital-rise/campaign/:id/form/step-one',
        redirect: 'fundraising/campaign/:id/form/step-one'
    },
    {
        path: 'capital-rise/campaign/:id/form/step-two',
        redirect: 'fundraising/campaign/:id/form/step-one'
    },
    {
        path: 'capital-rise/campaign/:id/form/step-three',
        redirect: 'fundraising/campaign/:id/form/step-one'
    },
    {
        path: 'capital-rise/campaign/:id/form/step-four',
        redirect: 'fundraising/campaign/:id/form/step-one'
    },
    {
        path: 'fundraising/new-campaign/preview',
        name: 'newCampaignPreview',
        alias: ['investors/new-campaign/preview'],
        component: newCampaignPreviewComponentModule,
    },
    {
        path: 'capital-rise/new-campaign/preview',
        redirect: 'fundraising/new-campaign/preview'
    }
];
