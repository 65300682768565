var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "19px",
      "height": "18px",
      "viewBox": "0 0 19 18",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('g', {
    attrs: {
      "id": "Page-1",
      "stroke": "none",
      "stroke-width": "1",
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('g', {
    attrs: {
      "id": "autenti-icon",
      "fill-rule": "nonzero"
    }
  }, [_c('polygon', {
    attrs: {
      "id": "Path",
      "fill": "#1872E7",
      "points": "12 11.7307692 8.80373831 18 6 12.53125 8.80373831 12.53125 10.6028037 9"
    }
  }), _c('polygon', {
    attrs: {
      "id": "Path",
      "fill": "#29333D",
      "points": "9.50693432 0 19 18 15.8240876 18 9.50693432 5.98388954 3.18053529 18 0 18"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }