import {BACKEND_BASE_URL} from "@/shared/consts";
import {RouteConfig} from "vue-router";
import store from "@/store/rootStore";

const capitalModule = () => import('./containers/capitalModule.vue');
const capitalBlindlModule = () => import('./containers/capitalBlindModule.vue');

export const capitalRouting: Array<RouteConfig> = [
    {
        path: 'capital',
        // beforeEnter: (to, from, next) => {
            // window.location.href = `${BACKEND_BASE_URL}/fund/capital/by-nominal-value`
        // },
        meta: {
            breadcrumbs: [
                { text: 'Home', href: '/welcome' },
                { text: ':currentContext', href: '/:currentContext/dashboard' },
                { text: 'Capital', href: '' },
            ]
        },
        name: 'capital',
        component: capitalModule,
        async beforeEnter(to, from, next) {
            let activeUserData = store.getters['genprox/activeUserData'];
            if(!activeUserData) {
                activeUserData = await store.dispatch('genprox/getActiveUserData');
            }

            const isPortfolioCompany = to.path.includes('/company') ? true : false;
            if(!isPortfolioCompany) {
                if(activeUserData?.context?.context == 'fund') {
                    await store.dispatch('genprox/getAccessModules');
                    const accessModules = store.getters['genprox/accessModules'];
                    if(accessModules.capital) {
                        next();
                    } else {
                        next(`${to.path}/access-denied`);
                    }
                } else {
                    const viewedFund = await store.dispatch('auth/getViewedFund');
                    const leAccessModules = await store.dispatch('genprox/getLegalEntityAccessModules', viewedFund);

                    if(leAccessModules.capital) {
                        next();
                    } else {
                        next(`${to.path}/access-denied`);
                    }
                }
            } else {
                if(activeUserData?.context?.context == 'company') {
                    await store.dispatch('genprox/getAccessModules');
                    const accessModules = store.getters['genprox/accessModules'];
                    if(accessModules.capital) {
                        next();
                    } else {
                        next(`${to.path}/access-denied`);
                    }
                } else {
                    const viewedCompany = await store.dispatch('auth/getViewedCompany');
                    const leAccessModules = await store.dispatch('genprox/getLegalEntityAccessModules', viewedCompany);
                    
                    if(leAccessModules.capital) {
                        next();
                    } else {
                        next(`${to.path}/access-denied`);
                    }
                }

                
            }
        },
    },
    {
        path: 'capital/access-denied',
        name: 'capital-access-denied',
        meta: {
            accessModule: ['capital'],
        },
        component: capitalBlindlModule,
    }
];
