var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M8.09366 12.75L12.0937 11.5937L11.8437 12.0312C11.6562 12.375 11.7812 12.8125 12.1249 13C12.2187 13.0625 12.3437 13.0937 12.4687 13.0937C12.7187 13.0937 12.9687 12.9687 13.0937 12.7187L14.1874 10.7812C14.2812 10.625 14.3124 10.4375 14.2499 10.25C14.1874 10.0625 14.0624 9.90625 13.9062 9.84375L11.8749 8.71875C11.5312 8.53125 11.0937 8.65625 10.9062 9C10.7187 9.34375 10.8437 9.78125 11.1874 9.96875L11.7499 10.2812L7.71866 11.4375C7.34366 11.5312 7.12491 11.9375 7.24991 12.3125C7.37491 12.6875 7.71866 12.8437 8.09366 12.75Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.4375 5.625H8.5C8.125 5.625 7.78125 5.9375 7.78125 6.34375C7.78125 6.75 8.09375 7.0625 8.5 7.0625H9.59375V7.25C9.59375 7.625 9.90625 7.96875 10.3125 7.96875C10.7188 7.96875 11 7.65625 11 7.25V6.96875C11.6875 6.75 12.1875 6.09375 12.1875 5.3125C12.1875 4.34375 11.4063 3.5625 10.4375 3.5625H9.53125C9.34375 3.5625 9.1875 3.40625 9.1875 3.21875C9.1875 3.03125 9.34375 2.875 9.53125 2.875H11.4688C11.8438 2.875 12.1875 2.5625 12.1875 2.15625C12.1875 1.75 11.875 1.4375 11.4688 1.4375H10.9687V1.25C10.9687 0.875 10.6563 0.53125 10.2813 0.53125C9.90625 0.53125 9.5625 0.84375 9.5625 1.25V1.4375H9.5C8.53125 1.4375 7.75 2.21875 7.75 3.1875C7.75 4.15625 8.53125 4.9375 9.5 4.9375H10.4062C10.5937 4.9375 10.75 5.09375 10.75 5.28125C10.8125 5.46875 10.6563 5.625 10.4375 5.625Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "d": "M16.7188 3.1875H14.0312C13.6562 3.1875 13.3125 3.5 13.3125 3.90625C13.3125 4.3125 13.625 4.625 14.0312 4.625H16.7188C16.9688 4.625 17.1875 4.84375 17.1875 5.09375V13.6562C17.1875 13.9062 16.9688 14.125 16.7188 14.125H10H3.28125C3.03125 14.125 2.8125 13.9062 2.8125 13.6562V5.0625C2.8125 4.8125 3.03125 4.59375 3.28125 4.59375H5.96875C6.34375 4.59375 6.6875 4.28125 6.6875 3.875C6.6875 3.46875 6.375 3.15625 5.96875 3.15625H3.28125C2.25 3.15625 1.40625 4 1.40625 5.03125V13.5937C1.40625 14.625 2.25 15.4687 3.28125 15.4687H9.28125V18H7.0625C6.6875 18 6.34375 18.3125 6.34375 18.7187C6.34375 19.125 6.65625 19.4375 7.0625 19.4375H12.9062C13.2812 19.4375 13.625 19.125 13.625 18.7187C13.625 18.3125 13.3125 18 12.9062 18H10.6875V15.4687H16.6875C17.7188 15.4687 18.5625 14.625 18.5625 13.5937V5.0625C18.5938 4.03125 17.75 3.1875 16.7188 3.1875Z",
      "fill": "currentColor"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }