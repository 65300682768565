import {RouteConfig} from "vue-router";
// import {BACKEND_BASE_URL} from "@/shared/consts";
import store from "@/store/rootStore";
import { REDIRECTED_PATH } from "../../genprox.routing";
// import { REDIRECTED_PATH } from '@/modules/genprox/genprox.routing';

const pivotContainer = () => import('@/modules/genprox/containers/PivotContainer.vue');
const blindPivotContainer = () => import('@/modules/genprox/containers/BlindPivotContainer.vue');

export const analyticsRouting : Array<RouteConfig> = [
    {
        path: 'analytics',
        name: 'analytics',
        component: pivotContainer,
        meta: {
            breadcrumbs: [
                { text: 'Home', href: '/welcome' },
                { text: ':currentContext', href: '/:currentContext/dashboard' },
                { text: 'Analytics', href: '' },
            ]
        },
        async beforeEnter(to, from, next) {
            let activeUserData = store.getters['genprox/activeUserData'];
            const accessModules = await store.dispatch('genprox/getAccessModules');

            if (!activeUserData) {
                activeUserData = await store.dispatch('genprox/getActiveUserData')
            }
            
            if (activeUserData?.role?.workflow?.includes('analyst') && accessModules?.accounting) {
                next()
            } else {
                const linkParentPath = to?.fullPath?.includes('/fund-manager') ? 'fund-manager' : to?.fullPath?.includes('/company') ? 'company' : 'fund'
                next(`/${linkParentPath}/analytics/access-denied`)
            }
        }
    },
    {
        path: 'analytics/access-denied',
        name: 'analytics-access-denied',
        meta: {
            accessModule: ['accounting'],
        },
        component: blindPivotContainer,
    },
    {
        path: 'analytics/:reportName',
        name: 'analytics-report',
        component: pivotContainer,
        meta: {
            breadcrumbs: [
                { text: 'Home', href: '/welcome' },
                { text: ':currentContext', href: '/:currentContext/dashboard' },
                { text: 'Analytics', href: '/:currentContext/analytics' },
                { text: ':title', href: '' }
            ]
        },
        async beforeEnter(to, from, next) {
            let activeUserData = store.getters['genprox/activeUserData'];
            const accessModules = await store.dispatch('genprox/getAccessModules');

            if (!activeUserData) {
                activeUserData = await store.dispatch('genprox/getActiveUserData')
            }

            const linkParentPath = to?.fullPath?.includes('/fund-manager') ? 'fund-manager' : to?.fullPath?.includes('/company') ? 'company' : 'fund'

            if (activeUserData?.role?.workflow?.includes('analyst') && accessModules?.accounting) {
                let pathName = window.location.href;
                pathName = pathName?.replace(window.location.origin, '')

                if (!activeUserData && pathName != '/auth/login') {
                    localStorage.setItem(REDIRECTED_PATH, pathName)
                }

                const contextData = store.getters['accounting/getContextData'];

                if(to.query?.contextOwnerId) {
                    await store.dispatch('genprox/getUserContexts');
                    const contexts = store.getters['genprox/userContexts']
                    const correctContext = contexts.find((el: any) => el.id === to?.query?.contextOwnerId);
                    await store.dispatch('genprox/setUserContext', { id: to.query.contextOwnerId, context: correctContext?.context });
                    
                    if(!contextData?.id) {
                        const resp = await store.dispatch('genprox/getContextData');
                        store.commit('accounting/setContextData', resp);
                    }

                    const params = window?.location?.search
                    const queryParams = new URLSearchParams(params)
                    queryParams.delete('contextOwnerId')
                    const finalLink = `${to?.path}?${queryParams?.toString()}`
                    
                    next(finalLink)
                } else {
                    next()
                }
            } else {
                next(`/${linkParentPath}/analytics/access-denied`)
            }
        },
    }
];
